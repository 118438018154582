html,
body,
#root {
  height: 100%;
}

.App {
  height: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  background: black;
}

.back-image {
  width: 100%;
}